/* Reset default margins and ensure full height */
body {
  margin: 0;
  padding: 0;
}

/* Flex container for the main layout */
.container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

/* Image columns styling */
.image-column {
  width: 8%; /* Matches your desired width */
  flex-shrink: 0; /* Prevents columns from shrinking */
  border: 5px solid black;
  overflow-y: auto; /* Scroll if images exceed height */
}

.image-column img {
  width: 100%;
  height: auto;
  display: block; /* Removes gaps between images */
}

/* Center content styling */
.content {
  flex-grow: 1; /* Takes all remaining space between image columns */
  padding: 20px;
  overflow-y: auto; /* Scroll if content exceeds height */
}

/* Style for iframes */
.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-bottom: 20px; /* Space between iframes */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Additional styling for your elements */
.columntes {
  font-size: 30px;
}

.column {
  width: 28%;
}

h4 {
  color: black;
  background: white;
  text-align: center;
  padding-bottom: 0;
  font-size: 10px;
  margin: 0;
}

.labels {
  text-align: center;
  margin: 20px 0;
}

.row {
  width: 100%;
}

.carded {
  display: flex;
  overflow-x: scroll;
}

.cardvid--content img {
  width: 400px;
  height: auto;
  margin-right: 10px;
}