/* Full-width .header with centered content */
.header {
  background: url('https://thedailydialectics.com/imgs/header_background.jpg') no-repeat center/cover;
  min-height: 2em;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  font-weight: bold;
  width: 100%; /* Full width */
  margin: 0;
  padding: 0; /* Remove padding if not needed */
}

.headerContent {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%; /* Full width */
  margin: 0;
  padding: 0;
}

/* Link styling for the header title */
.TDDh {
  text-decoration: none;
  font-size: 1.5vw;
  margin-right: 1rem; /* Adjust as needed */
  color: #ffffff !important;
  margin: 0;
  font-weight: bold !important;
}

/* Static navbar styles */
.nav {
  text-align: center;
  margin: 0;
  width: 100%;
}

.nav a {
  margin: 0;
  color: inherit; /* Inherit the parent's color */
  text-decoration: none; /* Optional: remove underline if desired */
}

/* Optional: Global anchor reset for the header */
.header a {
  color: inherit;
  text-decoration: none;
}
