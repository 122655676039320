/* Full-width .navContainer with centered content */
.navContainer {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: url('https://thedailydialectics.com/imgs/grey_background_960_720.jpg') no-repeat center/cover;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Optional: Shadow effect when scrolled */
.navContainer.scrolled {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navBar {
  width: 100%;
}

.navBarInner {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Adds spacing between .dropdown elements */
  padding: 5px 0; /* Optional: Vertical padding for the row */
}

/* Dropdown styling */
.dropdown {
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  flex: 0 0 auto; /* Replaces fixed width, sizes to content */
}

/* Button styles */
.dropBtn {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 15px;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: normal;
  transition: background 0.3s ease, transform 0.2s ease;
  display: block;
}

/* Hover/focus effects */
.dropBtn:hover,
.dropBtn:focus {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3));
  transform: translateY(-1px);
}

/* Dropdown Content styling */
.dropdownContent {
  border: 3px solid #ccc;
  display: none;
  position: absolute;
  background-color: #555;
  min-width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10001;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  max-height: calc(40px * 5);
  overflow-y: auto;
  pointer-events: auto;
}

/* Dropdown Content Links */
.dropdownContent a {
  text-align: center;
  border-bottom: 1px solid #666;
  color: #fff;
  padding: 10px;
  text-decoration: none;
  display: block;
  background-color: rgba(180, 185, 177, 0.55);
  cursor: pointer;
  font-size: 14px;
  font-family: 'Times New Roman', Times, serif;
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
}

.dropdownContent a:last-child {
  border-bottom: none;
}

/* Show dropdown content when active */
.dropdown.active .dropdownContent {
  display: block;
}

/* Hover effects */
.dropBtn:hover,
.dropdownContent a:hover {
  background-color: #555;
}

/* Active state */
.active {
  background-color: #666;
  color: #fff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navContainer {
    padding: 10px 0;
    background: #333;
    opacity: 0.95;
  }

  .navBarInner a {
    font-size: 16px;
  }
}